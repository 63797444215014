
<div class="image-cropper">
  <div
    class="image-cropper-container"
    :style="`width: ${containerWidth}px; height: ${containerHeight}px; max-width: ${containerMaxWidth}px; max-height: ${containerMaxHeight}px;`"
  >
    <div class="image-cropper-container-image" :class="{ square: isSquare }">
      <!-- IMPORTANT NOTE:
          image tag must be wrapped in a div when the size of the image is smaller thant 500x280
      -->
      <div>
        <img ref="image" :src="null" style="display: block; max-width: 100%" />
      </div>
    </div>
  </div>
  <div class="image-cropper-name" :style="`width: ${containerWidth}px;`">{{ fileName }}</div>
  <div class="image-cropper-buttons">
    <div class="image-cropper-buttons-btn">
      <submit-button btnStyle="tertiary" :label="cancelButtonLabel" @submit="cancel" />
    </div>
    <div class="image-cropper-buttons-btn">
      <submit-button :label="submitButtonLabel" @submit="cropImage" />
    </div>
  </div>
</div>
